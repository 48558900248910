<template>
  <div class="login home">
    <img src="@/assets/img/guangfa/home-bg.png" />
    <div class="content content2">
      <form>
        <div class="loginflex">
          <label>下发用户手机号：</label>
          <input placeholder="请输入手机号" v-model="submitForm.phone" />
        </div>
        <div class="loginflex loginyzm" style="padding-right: 0">
          <label>验证码：</label>
          <input placeholder="请输入验证码" v-model="submitForm.captcha" />
          <div :class="btn.disable?'getcode disabled':'getcode'" @click="getCodeMeds"
          >
            {{btn.disable?`${btn.num}s`:'获取验证码'}}
          </div>
        </div>
        <div class="submit" @click="submitMeds">确认下发</div>
      </form>
    </div>
    <div class="content content3">
      <h4>温馨提示</h4>
      <p>
        1、提交订单时所填写的手机号会在下单成功后收到包含卡号、密码短信，请注意查收，请在有效期内使用，过期不退不换不补偿。
      </p>
      <p>2、下单前请务必确认手机号码无误，下发后无法更改。</p>
      <p>3、如对电子卡券有任何疑问，请联系400客服。</p>
      <p>4、本卡券不开具任何发票。</p>
      <p>5、不找零、不兑现，消费超额需补足超出现金。</p>
      <div class="logintips">
        如有疑问请咨询客服电话<a href="tel:400-882-6000">400-882-6000</a>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div style="padding-bottom:5.46vw">下发成功</div>
        <div>请注意查收短信</div>
        <div class="flexbuton">
          <div @click="stay"><a>确认并留在原页面</a></div>
          <div>
            <router-link tag="a" :to="{ name: 'GuanfaExchangeHome' }"
              >回到主页</router-link
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  reqGuangfaSendCaptcha,
  reqGuangfaSendCard
} from '@/api/guangfa-project'
export default {
  name: 'ExchangePage',
  inject: ["reload"],
  data() {
    return {
      submitForm: {
        phone: undefined,
        captcha: undefined
      },
      btn:{
        disable:false,
        timer:null,
        num:60
      },
      show: false,
    }
  },
  created() {},
  methods: {
    stay(){
      this.reload();//刷新
    },
    async submitMeds() {
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.submitForm.phone)) {
        this.$toast.fail('请填写正确的手机号')
        return false
      }
      if (this.submitForm.captcha !== 0 && !this.submitForm.captcha) {
        this.$toast.fail('请填写验证码')
        return false
      }
      const data = await reqGuangfaSendCard(this.submitForm)
      if (data && data.code === 0) {
        this.show = true
      }
    },
    async getCodeMeds() {
      if (!this.btn.disable) {
        const reg = /^1[3456789]\d{9}$/
        if (this.submitForm.phone && reg.test(this.submitForm.phone)) {
          const data = await reqGuangfaSendCaptcha({
            phone: this.submitForm.phone,
            operatorTag: 0 // 操作员标识
          })
          if (data && data.code === 0) {
            this.$toast.success(data.message)
            this.btn.disable = true;
            this.btn.num = 60;
            this.btn.timer = setInterval(() =>{
              this.btn.num--
              if (this.btn.num <=0){
                this.btn.disable = false;
                this.btn.timer=null;
              }
            },1000)
          }else{
            this.btn.disable = false;
          }
        } else {
          this.btn.disable = false;
          this.$toast.fail('请填写正确的手机号')
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/style/guangfa.less';
.wrapper {
  width: 90%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2.13vw;
  padding: 12vw 4.93vw 5.73vw;
  box-sizing: border-box;
  text-align: center;
  color: #4b4b4b;
  font-size: 4vw;
  .flexbuton {
    display: flex;
    margin-top: 8.53vw;
    & > div {
      flex: 1;
      a {
        background-color: #be0117;
        width: 35.6vw;
        line-height: 8.8vw;
        border-radius: 5.3vw;
        font-size: 3.73vw;
        display: block;
        margin: auto;
        color: #fff;
      }
    }
  }
}
</style>
